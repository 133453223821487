import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';

import settingsReducer from './slices/theme/setting';
import layoutReducer from './slices/theme/layout';
import langReducer from './slices/theme/language';
import authReducer from './slices/theme/auth';
import menuReducer from './slices/theme/menu';
import notificationReducer from './slices/theme/notification';
import scrollspyReducer from './slices/theme/scrollspy';

import calendarReducer from './slices/app/calendar';
import contactsReducer from './slices/app/contacts';
import chatReducer from './slices/app/chat';
import mailboxReducer from './slices/app/mail';
import tasksReducer from './slices/app/tasks';

import { REDUX_PERSIST_KEY } from './config';

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ['menu', 'settings', 'lang'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    settings: settingsReducer,
    layout: layoutReducer,
    lang: langReducer,
    auth: authReducer,
    menu: menuReducer,
    notification: notificationReducer,
    scrollspy: scrollspyReducer,
    calendar: calendarReducer,
    contacts: contactsReducer,
    chat: chatReducer,
    mailbox: mailboxReducer,
    tasks: tasksReducer,
  })
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistedStore = persistStore(store);

export { store, persistedStore };
