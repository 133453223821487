import React, { lazy } from 'react';

import { DEFAULT_PATHS } from '../store/config';

const MyPage = lazy(() => import('../views/MyPage'));
const Dashboard = lazy(() => import('../views/Dashboard'));
const Channel = lazy(() => import('../views/Channel'));
const NotFound = lazy(() => import('../views/default/NotFound'));
const Notification = {
  Announcement: lazy(() => import('../views/notification/Announcement')),
  MyLog: lazy(() => import('../views/notification/MyLog'))
}
const Customer = {
  Manager: lazy(() => import('../views/customer/Manager')),
  Db: lazy(() => import('../views/customer/Db')),
  List: lazy(() => import('../views/customer/List')),
  Progress: lazy(() => import('../views/customer/Progress')),
  CompleteList: lazy(() => import('../views/customer/CompleteList')),
  Expired: lazy(() => import('../views/customer/Expired')),
}

const Team = lazy(() => import('../views/Team'));
const MyCustomer = lazy(() => import('../views/MyCustomer'));

const Operate = {
  Organization: lazy(() => import('../views/operate/Organization')),
  Notification: lazy(() => import('../views/operate/Notification')),
  AlimTalk: lazy(() => import('../views/operate/AlimTalk')),
  Setting: lazy(() => import('../views/operate/Setting')),
}
const Service = {
  Status: lazy(() => import('../views/service/Status')),
  Partner: lazy(() => import('../views/service/Partner')),
  Admin: lazy(() => import('../views/service/Admin')),
  Payment: lazy(() => import('../views/service/Payment'))
}

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/dashboard`
    },
    {
      path: `${appRoot}/dashboard`,
      element: <Dashboard />,
      label: '대시보드',
      icon: 'grid-5'
    },
    {
      path: `${appRoot}/channel`,
      element: <Channel />,
      label: '채널관리',
      roles: ['SUPER_ADMIN', 'ADMIN'],
      icon: 'youtube'
    },
    {
      path: `${appRoot}/customer`,
      roles: ['SUPER_ADMIN', 'ADMIN'],
      label: '고객관리',
      icon: 'user',
      element: <Customer.Manager />,
      subs: [
        { path: '/db', label: '고객DB', element: <Customer.Db />, roles: ['SUPER_ADMIN'] },
        { path: '/list', label: '고객리스트', element: <Customer.List />, roles: ['SUPER_ADMIN', 'ADMIN'] },
        // { path: '/progress', label: '계약완료리스트', element: <Customer.Progress />, roles: ['SUPER_ADMIN', 'ADMIN'] },
        { path: '/complete', label: '계약완료리스트', element: <Customer.CompleteList />, roles: ['SUPER_ADMIN', 'ADMIN'] },
        // { path: '/expired', label: '만료된 계약', element: <Customer.Expired />, roles: ['SUPER_ADMIN', 'ADMIN'] }
      ]
    },
    {
      path: `${appRoot}/team`,
      element: <Team />,
      label: '팀고객리스트',
      roles: ['TEAM_LEADER'],
      icon: 'diagram-2'
    },
    {
      path: `${appRoot}/mycustomer`,
      element: <MyCustomer />,
      label: '담당고객리스트',
      roles: ['TEAM_LEADER', 'TEAM_MEMBER'],
      icon: 'diagram-2'
    },
    {
      path: `${appRoot}/notification`,
      label: '알림',
      icon: 'notification',
      subs: [
        { path: '/announcement', label: '공지사항', element: <Notification.Announcement />, roles: ['TEAM_LEADER', 'TEAM_MEMBER'], },
        { path: '/my-log', label: '나의 활동', element: <Notification.MyLog /> }
      ]
    },
    {
      path: `${appRoot}/operate`,
      label: '운영관리',
      icon: 'online-class',
      roles: ['SUPER_ADMIN', 'ADMIN'],
      subs: [
        { path: '/organization', label: '조직관리', element: <Operate.Organization /> },
        { path: '/notification', label: '공지등록', element: <Operate.Notification /> },
        { path: '/alimtalk', label: '알림톡발송', element: <Operate.AlimTalk /> },
        { path: '/setting', label: '설정관리', element: <Operate.Setting /> },
      ]
    },
    {
      path: `${appRoot}/service`,
      label: '서비스관리',
      icon: 'tool',
      roles: ['SUPER_ADMIN'],
      subs: [
        { path: '/status', label: '서비스 현황', element: <Service.Status /> },
        { path: '/partner', label: '파트너 관리', element: <Service.Partner /> },
        { path: '/admin', label: '관리자 계정', element: <Service.Admin /> },
        { path: '/payment', label: '결제이력', element: <Service.Payment /> },
      ]
    }
  ],
  sidebarItems: [],
  extraItems: [
    {
      path: '/me',
      element: <MyPage />
    },
    {
      path: '*',
      element: <NotFound />,
    }
  ]
};

export default routesAndMenuItems;