import React, { lazy } from 'react';
import { DEFAULT_PATHS } from '../store/config';

const NotFound = lazy(() => import('../views/default/NotFound'));
const Login = lazy(() => import('../views/default/Login'));
const App = lazy(() => import('../App'));

const defaultRoutes = [
  { path: DEFAULT_PATHS.NOTFOUND, exact: true, element: <NotFound /> },
  { path: '/login', exact: true, element: <NotFound /> },
  { path: DEFAULT_PATHS.LOGIN, exact: true, element: <Login /> },
  { path: '*', element: <App /> },
];

export default defaultRoutes;