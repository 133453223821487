import React, { memo, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { DEFAULT_PATHS } from '../../store/config';

const RouteIdentifier = ({ routes, fallback = <div className="loading" />, notFoundPath = DEFAULT_PATHS.NOTFOUND }) => {
  return (
    <Suspense fallback={fallback}>
      <Routes>
        {routes.map((route, rIndex) => !route.redirect ? (
          <Route key={`r.${rIndex}`} {...route} />
        ) : (
          <Route key={`r.${rIndex}`} path={route.path} element={<Navigate replace to={route.to} />} />
        ))}
      </Routes>
    </Suspense>
  )
}

export default memo(RouteIdentifier);
