import React, { useMemo, Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { Helmet } from 'react-helmet';
import { Slide, ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { CookiesProvider } from 'react-cookie';

import { store, persistedStore } from './store';
import { REACT_HELMET_PROPS } from './store/config';
import routesAndMenuItems from './route';
import { getLayoutlessRoutes } from './route/helper';
import defaultRoutes from './route/default';
import Loading from './components/Loading';
import LangProvider from './language/LangProvider';
import RouteIdentifier from './route/components/RouteIdentifier';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Main = () => {
  const layoutlessRoutes = useMemo(() => getLayoutlessRoutes({ data: routesAndMenuItems }), []);

  return (
    <Fragment>
      <Provider store={store}>
        <CookiesProvider>
          <PersistGate loading={null} persistor={persistedStore}>
            <Helmet {...REACT_HELMET_PROPS} />
            <ToastContainer transition={Slide} newestOnTop />
            <Router basename={process.env.REACT_APP_BASENAME}>
              <LangProvider>
                <RouteIdentifier routes={[...layoutlessRoutes, ...defaultRoutes]} fallback={<Loading />} />
              </LangProvider>
            </Router>
          </PersistGate>
        </CookiesProvider>
      </Provider>
    </Fragment>
  );
};

root.render(<Main />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
